import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  
  const goto = (e) => {
    if(e) {
      if(e !== "aboutus" && e !== "gallery" && e  !== "contact") updateNavbar(e);
      else {
        if(window.location.pathname !== "/") {
          window.location.href = '/#'+e;
        }
      }
      setClick(false);
      let target = document.getElementById(e);
      if(target) {
        target.scrollIntoView({ 
          behavior: 'smooth' 
        });
      }
    }
  }

  const ScrollToTopOnMount = () => {
    window.scrollTo(0, 0);
  }

  const updateNavbar = (e) => {
    ScrollToTopOnMount();
    setClick(false);
    let navitems = document.getElementsByClassName("nav-links");
    let navlogos = document.getElementsByClassName("navbar-logo");
    let navbar = document.getElementsByClassName("navbar");

    if(navitems) {
      if(e.includes("pricelist")) {
        Array.from(navitems).forEach(element => {
          if(!element.classList.contains("navpricelist")) {
            element.classList.add("navpricelist");
          }
        });
      } else {
        Array.from(navitems).forEach(element => {
          if(element.classList.contains("navpricelist")) {
            element.classList.remove("navpricelist");
          }
        });
      }
    }
    if(navlogos) {
      if(e.includes("pricelist")) {
        Array.from(navlogos).forEach(element => {
          if(!element.classList.contains("navpricelist")) {
            element.classList.add("navpricelist");
          }
        });
        Array.from(navbar).forEach(element => {
          if(element.classList.contains("navbar-fixed-top")) {
            element.classList.remove("navbar-fixed-top")
            element.classList.add("navbar-fixed-top-dark");
          }
        });
      } else {
        Array.from(navlogos).forEach(element => {
          if(element.classList.contains("navpricelist")) {
            element.classList.remove("navpricelist");
          }
        });
        Array.from(navbar).forEach(element => {
          if(element.classList.contains("navbar-fixed-top-dark")) {
            element.classList.remove("navbar-fixed-top-dark");
            element.classList.add("navbar-fixed-top");
          }
        });
      }
    }
  }
  

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    updateNavbar(window.location.pathname);
  }, []);

  const fixateOnTop = () => {
    
    if(window.scrollY > 500) {
      let navbar = document.getElementsByClassName("navbar")[0];
      if(window.location.pathname === "/pricelist") {
        navbar.classList.add("navbar-fixed-top-dark");
        if(navbar.classList.contains("navbar-fixed-top")) navbar.classList.remove("navbar-fixed-top");
      } else {
        document.getElementsByClassName("navbar")[0].classList.add("navbar-fixed-top");
        if(navbar.classList.contains("navbar-fixed-top-dark")) navbar.classList.remove("navbar-fixed-top-dark");
      }
    } else {
      document.getElementsByClassName("navbar")[0].classList.remove("navbar-fixed-top-dark");
      document.getElementsByClassName("navbar")[0].classList.remove("navbar-fixed-top");
    }
  }

  window.addEventListener('resize', showButton);
  window.addEventListener('scroll', fixateOnTop);

  return (
    <>
      <nav className={'navbar'}>
        <div className='navbar-container'>
          <Link id="homebutton" to='/' 
          className={'navbar-logo '+(window.location.pathname.startsWith("/pricelist") ? "navpricelist" : "")} 
          onClick={() => goto("home")}>
            Happy Nails
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? (window.location.pathname.startsWith("/pricelist") ? 'fas fa-times dark' : 'fas fa-times') : (window.location.pathname.startsWith("/pricelist") ? 'fas fa-light fa-bars dark' : 'fas fa-light fa-bars')} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link
                to='#aboutus'
                className={'nav-links '+(window.location.pathname.startsWith("/pricelist") ? "navpricelist" : "")}
                onClick={() => goto("aboutus")}
              >
                Über Uns
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='#gallery'
                className={'nav-links '+(window.location.pathname.startsWith("/pricelist") ? "navpricelist" : "")}
                onClick={() => goto("gallery")}
              >
                Gallerie
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='#contact'
                className={'nav-links '+(window.location.pathname.startsWith("/pricelist") ? "navpricelist" : "")}
                onClick={() => {goto("contact")}}
              >
                Kontakt
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/pricelist'
                onClick={() => updateNavbar("pricelist")}
                className={'nav-links '+(window.location.pathname.startsWith("/pricelist") ? "navpricelist" : "")}
              >
                Preisliste
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/impressum'
                className={'nav-links '+(window.location.pathname.startsWith("/pricelist") ? "navpricelist" : "")}
                onClick={() => updateNavbar("impressum")}
              >
                Impressum
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default withRouter(Navbar);
