import React from 'react';
import './Pricelist.css';

function Pricelist() {
  return (
    <div id="pricelist" >
      <div className='pricelist-container'>
        <div className='cards__wrapper'>
        <center className="note">
          <p>Stand: 24.01.2023</p>
        </center>
        <table>
        <tbody>
                            <tr>
                                <td></td>
                                <td colSpan="2"><center>Preis &#91;€&#93;</center></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><center>Acryl</center></td>
                                <td><center>Gel</center></td>
                            </tr>
                            <tr>
                                <td className="noBorder">1 Vollmodellage in rosa oder durchsichtig</td>
                                <td className="noBorder" colSpan="2"></td>
                            </tr>
                            <tr>
                                <td className="noBorder">- Kunstnägel</td>
                                <td className="noBorder" align="center">28.00</td>
                                <td className="noBorder" align="center">32.00</td>
                            </tr>
                            <tr>
                                <td className="noBorder">- Naturverstärkung</td>
                                <td className="noBorder" align="center">25.00</td>
                                <td className="noBorder" align="center">30.00</td>
                            </tr>
                            <tr>
                                <td className="noBorder">- Nachfüllung</td>
                                <td className="noBorder" align="center">22.00</td>
                                <td className="noBorder" align="center">25.00</td>
                            </tr>
                            <tr>
                                <td>- 1 Neunagel</td>
                                <td align="center">3.00</td>
                                <td align="center">4.00</td>
                            </tr>
                            <tr>
                                <td className="noBorder">2 Vollmodellage mit Farbe</td>
                                <td className="noBorder" colSpan="2"></td>
                            </tr>
                            <tr>
                                <td className="noBorder">- Kunstnägel</td>
                                <td className="noBorder" align="center">38.00</td>
                                <td className="noBorder" align="center">42.00</td>
                            </tr>
                            <tr>
                                <td className="noBorder">- Naturverstärkung</td>
                                <td className="noBorder" align="center">35.00</td>
                                <td className="noBorder" align="center">40.00</td>
                            </tr>
                            <tr>
                                <td className="noBorder">- Nachfüllung</td>
                                <td className="noBorder" align="center">28.00</td>
                                <td className="noBorder" align="center">32.00</td>
                            </tr>
                            <tr>
                                <td>- 1 Neunagel</td>
                                <td align="center">4.00</td>
                                <td align="center">4.00</td>
                            </tr>
                            <tr>
                                <td className="noBorder">3 Vollmodellage mit Permanent French</td>
                                <td className="noBorder"></td>
                                <td className="noBorder"></td>
                            </tr>
                            <tr>
                                <td className="noBorder">- Kunstnägel</td>
                                <td className="noBorder" align="center">38.00</td>
                                <td className="noBorder" align="center">42.00</td>
                            </tr>
                            <tr>
                                <td className="noBorder">- Naturverstärkung</td>
                                <td className="noBorder" align="center">35.00</td>
                                <td className="noBorder" align="center">40.00</td>
                            </tr>
                            <tr>
                                <td className="noBorder">- Nachfüllung</td>
                                <td className="noBorder" align="center">32.00</td>
                                <td className="noBorder" align="center">40.00</td>
                            </tr>
                            <tr>
                                <td>- 1 Neunagel</td>
                                <td align="center">4.00</td>
                                <td align="center">4.00</td>
                            </tr>
                            <tr>
                                <td className="noBorder">4 Maniküre (Handpflege)</td>
                                <td className="noBorder" colSpan="2"><center>19.00</center></td>
                            </tr>
                            <tr>
                                <td colSpan="3"><small>- Handbad<br />
                                    - Kürzen und Feilen vom Nagelbett<br />
                                    - Nagelhaut abschneiden<br />
                                    - Massage</small></td>
                            </tr>
                            <tr>
                                <td className="noBorder">5 Pediküre (Fußpflege)</td>
                                <td className="noBorder" colSpan="2"><center>22.00</center></td>
                            </tr>
                            <tr>
                                <td colSpan="3"><small>- Fußbad<br />
                                    - Kürzen und Feilen vom Nagelbett<br />
                                    - Nagelhaut abschneiden<br />
                                    - Entfernung von Hornhaut mit elektr. Hornhautfeile<br />
                                    - Massage</small></td>
                            </tr>
                            <tr>
                                <td className="noBorder">6 Hornhautbehandlung mit Fußpflege</td>
                                <td className="noBorder" colSpan="2"><center>28.00</center></td>
                            </tr>
                            <tr>
                                <td colSpan="3"><small>(Details zur Fußpflege siehe oben)<br />
                                    - zusätzliche Behandlung mit einer Spezialsalbe </small></td>
                            </tr>
                            <tr>
                                <td className="noBorder">7 Hornhautbehandlung ohne Fußpflege</td>
                                <td className="noBorder" colSpan="2"><center>14.00</center></td>
                            </tr>
                            <tr>
                                <td colSpan="3"><small>- Fußbad<br />
                                    - Entfernung von Hornhaut mit elektr. Hornhautfeile<br />
                                    - zusätzliche Behandlung mit einer Spezialsalbe </small></td>
                            </tr><tr>
                                <td><h4 className="darkred angebot">Angebot: Hand- und Fußpflege</h4></td>
                                <td colSpan="2"><center>statt <strike>41€</strike> nur <b className="darkred">37€</b></center></td>
                            </tr>
                            <tr>
                                <td className="noBorder">Shellac auf allen Fingern</td>
                                <td className="noBorder" colSpan="2"><center>18.00</center></td>
                            </tr>
                            <tr>
                                <td colSpan="3"><small>- Extrem kurze Trockenzeit<br />
                                    - Perfektes Hochglanz-Finish<br />
                                    - Garantiert 14 Tage markelloses Ergebnis<br />
                                    - Keine Kratzer. Keine Macken. Garantiert</small></td>
                            </tr>
                            <tr>
                                <td>Farblackierung ohne Pflege</td>
                                <td colSpan="2"><center>5.00</center></td>
                            </tr>
                            <tr>
                                <td>Frenchlackierung ohne Pflege</td>
                                <td colSpan="2"><center>7.00</center></td>
                            </tr>
                            <tr>
                                <td>Farblackierung mit Nachfeilen</td>
                                <td colSpan="2"><center>8.00</center></td>
                            </tr>
                            <tr>
                                <td>Frenchlackierung mit Nachfeilen</td>
                                <td colSpan="2"><center>10.00</center></td>
                            </tr>
                            <tr>
                                <td>Ablösen</td>
                                <td colSpan="2"><center>10.00</center></td>
                            </tr>
                            <tr>
                                <td>French-Extra-Line</td>
                                <td colSpan="2"><center>3.00</center></td>
                            </tr>
                            <tr>
                                <td>Design für alle Finger</td>
                                <td colSpan="2"><center>ab 7.00</center></td>
                            </tr>
                            <tr>
                                <td>Strasssteine</td>
                                <td colSpan="2"><center>(je Stück) 0.50</center></td>
                            </tr>
                            <tr>
                                <td>Glass Finish Gel</td>
                                <td colSpan="2"><center>2.00</center></td>
                            </tr>
                            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Pricelist;
