import React from 'react';
import '../../App.css';
import Pricelist from '../Pricelist';
import { useEffect } from "react";

export default function Products() {
  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }
  return (
  <>
  <ScrollToTopOnMount/>
    <div className='products'><h1>Preisliste</h1></div>
    <Pricelist />
  </>
  )
  
}
