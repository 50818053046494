import React from 'react';
import { useEffect } from "react";
import '../../App.css';

export default function Impressum() {
  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }
  return (
  <>
  <ScrollToTopOnMount />
    <div className="services"><h1>Impressum</h1></div>
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__text'>
        <div className='cards__wrapper serv'>
        <p>Video by cottonbro: <a href="https://www.pexels.com/video/hands-hand-table-colorful-3997800/">https://www.pexels.com/video/hands-hand-table-colorful-3997800/</a></p>
        <p>&nbsp;</p>
        <p>
          <strong>Nagelstudio<br />
          Happy Nails<br />
          Sophie-Charlotten-Str. 46,<br />
          14059 Berlin</strong>
      </p>
      <p>
          Telefon: 030 / 501 74 120<br />
          Internet: <a href="http://happynails-berlin.de">happynails-berlin.de</a><br />
          E-Mail: <a href="mailto:info@happynails-berlin.de">info@happynails-berlin.de</a>
      </p>
      <p>
          Inhaberin: Thi Duy Man Phan<br />
          Verantwortlich für Bilder, Texte und das Erstellen der Website:<br />
          <a href="mailto:huyvietnguyen@hotmail.de">Huy Viet Nguyen</a>
      </p>
      <p>
        <strong>Datenschutz</strong><br />
      </p>
      <p>
        <strong>Plattform für außergerichtliche Streitschlichtung</strong>
        <br />
        Die EU-Kommission stellt eine Plattform für außergerichtliche Streitschlichtung bereit. 
        Verbrauchern gibt dies die Möglichkeit, Streitigkeiten im Zusammenhang mit 
        Ihrer Online-Bestellung zunächst ohne die Einschaltung eines Gerichts zu klären. 
        Die Streitbeilegungs-Plattform ist unter den externen Link erreichbar:
      </p>
                        
                        <p>
                            <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>
                        </p>
                        
                        <p>
                          Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor Verbraucher-<br />schlichtungsstellen teilzunehmen.
                        </p>
                        <p>
                          <strong>Gesetzlicher Hinweis</strong><br />
                          Die auf diesen Seiten veröffentlicht
                          en Bilder und Texte sind urheberrechtlich ges
                          chützt. Eine Vervielfältigung für private oder kommerzielle Z
                          wecke ist nur mit schriftlicher Genehmigung vom Nagelstudio Happy Nails zulässig.
                        </p>
                        <p>Für Fehler im Text oder falsche Links wird keine Haftung übernommen. 
                          Für die Inhalte externer Links sind die jeweiligen Anbieter verantwortlich.
                        </p>
                        <p>
                          <strong>1. Datenschutz auf einen Blick</strong>
                        </p>
                        <p>
                          <strong>Allgemeine Hinweise</strong>
                        </p> 
                        <p>
                          Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, 
                          was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. 
                          Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert
                           werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen 
                           Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
                           
                        </p>
                        <p>
                          <strong>Datenerfassung auf dieser Website</strong>
                        </p> 
                        <p>
                          <strong>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</strong>
                        </p> 
                        <p>
                          Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. 
                          Dessen Kontaktdaten k&ouml;nnen Sie dem Impressum dieser Website entnehmen.
                        </p> 
                        <p>
                          <strong>Wie erfassen wir Ihre Daten?</strong>
                        </p> 
                        <p>
                          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. 
                          Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
                        </p> 
                        <p>
                          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der 
                          Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten 
                          (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). 
                          Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
                        </p> 
                        <p>
                          <strong>Wof&uuml;r nutzen wir Ihre Daten?</strong>
                        </p> 
                        <p>
                          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website 
                          zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens 
                          verwendet werden.
                        </p> 
                        <p>
                          <strong>Welche Rechte haben Sie bez&uuml;glich Ihrer 
                            Daten?</strong>
                        </p> 
                        <p>
                          Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger 
                          und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem 
                          ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine 
                          Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung 
                          jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter 
                          bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen 
                          Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen 
                          Aufsichtsbeh&ouml;rde zu.
                        </p> 
                        <p>
                          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit unter 
                          der im Impressum angegebenen Adresse an uns wenden.
                        </p>
                        <p>
                          <strong>2. Allgemeine Hinweise und Pflichtinformationen</strong>
                        </p>
                        <p>
                          <strong>Datenschutz</strong>
                        </p>
                        <p>
                          Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. 
                          Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen 
                          Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.
                        </p> 
                        <p>
                          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. 
                          Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden 
                          k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir 
                          erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck 
                          das geschieht.
                        </p> 
                        <p>
                          Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der 
                          Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser 
                          Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
                        </p>
                        <p>
                          <strong>Hinweis zur verantwortlichen Stelle</strong>
                        </p> 
                        <p>
                          Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:
                        </p> 
                        <p>
                          Nagelstudio Happy Nails<br />
                          Thi Duy Man Phan<br />
                          Sophie-Charlotten-Str. 46,<br />
                          14059 Berlin
                        </p>

                        <p>
                          Telefon: 030 / 501 74 120<br />
                          E-Mail: <a href="mailto: info@happynails-berlin.de">info@happynails-berlin.de</a>
                        </p>
                         <p>Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.</p>
                         <p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p> <p>Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.</p>
                         <p><strong>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)</strong></p> <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p>
                         <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
                         <p><strong>Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde</strong></p> <p>Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
                        <p><strong>Recht auf Daten&uuml;bertragbarkeit</strong></p> <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
                        <p><strong>Auskunft, L&ouml;schung und Berichtigung</strong></p> <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>
                        <p><strong>Recht auf Einschr&auml;nkung der Verarbeitung</strong></p> <p>Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:</p> <ul> <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li> <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.</li> <li>Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li> <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li> </ul>
                        <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.</p>
                        <p><strong>3. Datenerfassung auf dieser Website</strong></p>
                        <p><strong>Anfrage per E-Mail, Telefon oder Telefax</strong></p> <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p> <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</p> <p>Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.</p>
                        <p><strong>4. Plugins und Tools</strong></p>
                        <p><strong>Google Web Fonts</strong></p> <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p> <p>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung von Google WebFonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein berechtigtes Interesse an der einheitlichen Darstellung des Schriftbildes auf seiner Webseite. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p> <p>Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> und in der Datenschutzerkl&auml;rung von Google: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
                        <p><strong>Google Maps</strong></p> <p>Diese Seite nutzt &uuml;ber eine API den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.</p> <p>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Daten&uuml;bertragung.</p> <p>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p> <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerkl&auml;rung von Google: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
                        <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
                        </div>       
                        </div>
      </div>
    </div>
  </>
  )
  
}
