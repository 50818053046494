import React from 'react';
import './Cards.css';

function Cards() {
  return (
    <div id="aboutus" className='cards'>
      <div className='cards__container'>
        <div className='cards__text'>
      <h1><span className="border">&nbsp;</span>Über Uns <span className="border right">&nbsp;</span></h1>
        <div className='cards__wrapper'>
        <p>
        Das Nagelstudio Happy Nails wurde erstmals am 16. Januar 2006 in Berlin-Charlottenburg eröffnet 
        und bietet seither einen Ort zur Entspannung und zum Wohlfühlen, 
        frei vom alltäglichen Stress für Ihre Hände, Füße und Sie.
      </p>
      <p>
        Ob Mann oder Frau, sei es Hand- oder Fußpflege, professionelles Nageldesign mit 
        Acryl, Gel oder Shellac oder die Unterstützung bei der Pflege von Hornhaut und Problemnägel:
      </p>
        <ul className="helpinglist">
          <li className="nice"> &nbsp;&nbsp;Splissnägel</li>
          <li className="nice"> &nbsp;&nbsp;Beißnägel</li>
          <li className="nice"> &nbsp;&nbsp;eingewachsene Nägel</li>
          <li className="nice"> &nbsp;&nbsp;dünne Naturnägel</li>
          <li className="nice"> &nbsp;&nbsp;Fußpilz</li>
          <li className="nice"> &nbsp;&nbsp;Sonstige Probleme</li>
        </ul>
      <p>
        das Nagelstudio Happy Nails ist ein Ort für jedenmann.
      </p>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
