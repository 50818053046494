import React from 'react';
import './Gallery.css';
import CardItem from './CardItem';

function Gallery() {
  return (
    <div className='cards' id="gallery">
      <h1><span className="border">&nbsp;</span>Gallery <span className="border right">&nbsp;</span></h1>
      
      <div className='cards__container2'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/ladenAussen.jpg'
              text='das Nagelstudio von außen'
            />
            <CardItem
              src='images/ladenInnen.jpg'
              text='das Nagelstudio von innen'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/PhanBackground.JPG'
              text='Inhaberin Frau Phan'
            />
            <CardItem
              src='images/colors.JPG'
              text='eine riesige Auswahl von Farben'
            />
            <CardItem
              src='images/arbeit.jpg'
              text='eine freundliche Atmosphäre'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/detail.jpg'
              text='großer Wert auf feine Details'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
