import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Products from './components/pages/Products';
import Impressum from './components/pages/Impressum';

function App() {

  useEffect(() => {
    document.title = 'Happy Nails Berlin';
  }, []);

  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/home' exact component={Home} />
          <Route path='/pricelist' component={Products} />
          <Route path='/impressum' component={Impressum} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
