import React from 'react';
import './Kontakt.css';

function Contact() {
  return (
    <div id="contact" className='contact_cards'>
      <div className='cards__container2'>
        <div className='cards__text'>
      <h1><span className="border">&nbsp;</span>Kontakt <span className="border right">&nbsp;</span></h1>
        <div className='cards__wrapper'>
          <div className="box">
        <div className="block">
          <iframe 
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2428.160632581708!2d13.288354000000002!3d52.512432!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfa633d5e7c0d3bb5!2sHappy%20Nails!5e0!3m2!1sen!2sde!4v1674494587085!5m2!1sen!2sde" 
        height="450" 
        style={{ border:0, display: "inline-block"}}
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
        title="Happy Nails Map Location"
        >

        </iframe>
        </div>
        <div className="block">
        <h2>Unsere Öffnungszeiten:</h2>
        <table>
                        <tbody><tr>
                            <td className="noBorder">Montag</td>
                            <td className="noBorder"></td>
                            <td colSpan="2">Ruhetag</td>
                        </tr>
                        <tr>
                            <td className="noBorder">Dienstag:</td>
                            <td className="noBorder"></td>
                            <td className="noBorder">10:00 - 18:00</td>
                            <td className="noBorder"></td>
                        </tr>
                        <tr>
                            <td className="noBorder">Mittwoch:</td>
                            <td className="noBorder"></td>
                            <td className="noBorder">10:00 - 18:00</td>
                            <td className="noBorder"></td>
                        </tr>
                        <tr>
                            <td className="noBorder">Donnerstag:</td>
                            <td className="noBorder"></td>
                            <td className="noBorder">10:00 - 18:00</td>
                            <td className="noBorder"></td>
                        </tr>
                        <tr>
                            <td className="noBorder">Freitag:</td>
                            <td className="noBorder"></td>
                            <td className="noBorder">10:00 - 18:00</td>
                            <td className="noBorder"></td>
                        </tr>
                        <tr>
                            <td>Samstag:</td>
                            <td className="noBorder"></td>
                            <td colSpan="2">Nur mit Terminvereinbarung</td>
                        </tr>
                   </tbody></table>
      <h2>Unsere Adresse:</h2>
      <p>
        Sophie-Charlotten-Str. 46
        <br />14059 Berlin
      </p>
      <p>In der Nähe von:</p>
      <p>
        U-Bhf Kaiserdamm (U2)<br/>
        U-Bhf Sophie-Charlotte-Platz (U2)<br/>
        S-Bhf Messe Nord/ICC (S41, S42)<br/>
        S-Bhf Westend (S41, S42)<br/>
        Bus Sophie-Charlotten-Str. (M45, 309)
      </p>
      <a href="https://goo.gl/maps/aLQFKs9wummkGxbn9" target="_blank" rel="noopener noreferrer">Klicken Sie hier um Google Maps zu öffnen.</a>
      </div>
      </div>
        <div className="secondbox">
        <div className='cards__wrapper'></div>
        <p>Haben Sie weitere Fragen oder wollen Sie einen Termin mit uns vereinbaren? Sie können uns unter folgenden Rufnummern während unserer Öffnungszeiten erreichen:</p>
        <br/>
        <table>
          <tbody><tr>
              <td className="noBorder">Telefon:</td>
              <td>&nbsp;&nbsp;&nbsp;</td>
              <td className="noBorder">030 50174120</td>
          </tr>
          <tr>
              <td>Mobil:</td>
              <td>&nbsp;&nbsp;&nbsp;</td>
              <td>0177 3026280</td>
          </tr>
          </tbody>
        </table>
      </div></div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
