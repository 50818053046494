import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/helpers/ScrollToTop";

ReactDOM.render(
    <Router>
        <ScrollToTop />
        <App />
    </Router>
, document.getElementById('root'));
