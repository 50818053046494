import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

const gotoo = (e) => {
  if(e) {
    let target = document.getElementById(e);
    if(target) {
      target.scrollIntoView({ 
        behavior: 'smooth' 
      });
    }
  }
}

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <Link to={children === "Kontakt" ? "#" : "/pricelist"} onClick={() => {
      children === "Kontakt" ? gotoo("contact") : gotoo("pricelist");
    }
    } className='btn-mobile'>
      <button
        className={`btn ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </Link>
  );
};
